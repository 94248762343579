import React, { useState, useContext, useMemo } from "react"
import styled, { css } from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { getShopifyImage } from "gatsby-source-shopify"

import { StoreContext } from "../context/store-context"
import NumberInput from "./NumberInput"

const CartLineItem = ({ item }) => {
  const [quantity, setQuantity] = useState(item.quantity)

  const { checkout, removeLineItem, updateLineItem } = useContext(StoreContext)
  if (!item.variant) {
  }

  const variantImage = {
    ...item?.variant?.image,
    originalSrc: item?.variant?.image?.src,
  }

  const variantDetails =
    item?.variant?.selectedOptions[0]?.name !== "Title"
      ? `${item?.variant?.selectedOptions[0]?.name}: ${item?.variant?.selectedOptions[0]?.value}`
      : null

  const image = useMemo(
    () =>
      getShopifyImage({
        image: variantImage,
        layout: "constrained",
        crop: "contain",
        width: 160,
        height: 160,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variantImage.src]
  )

  return (
    <LineItemRow>
      <LeftColumn key={item.id}>
        <LeftContainer>
          <h4
            style={{ fontWeight: 400 }}
          >{`${item.quantity} x ${item.title}`}</h4>

          {image && (
            <GatsbyImage
              key={variantImage.src}
              image={image}
              alt={variantImage.altText ?? item.variant.title}
            />
          )}
        </LeftContainer>
      </LeftColumn>
      <CartItemDetails>
        <ArticlePrice>
          {Intl.NumberFormat("de-DE", {
            currency: "EUR",
            minimumFractionDigits: 2,
            style: "currency",
          }).format(item.variant.priceV2.amount)}
        </ArticlePrice>
        {variantDetails && (
          <p
            style={{
              fontSize: "14px",
              marginBottom: 0,
              marginTop: 0,
              gridArea: "variant",
              textAlign: "center",
            }}
          >
            {variantDetails}
          </p>
        )}

        {item.customAttributes.length > 0 && (
          <div style={{ textAlign: "right", marginTop: "1rem" }}>
            {item.customAttributes.map(
              (att: any) =>
                att.value && (
                  <small key={att.key} style={{ display: "block" }}>
                    {`${att.key}: `}
                    <strong>{att.value}</strong>
                  </small>
                )
            )}
          </div>
        )}

        <QuantityWrapper>
          <NumberInput
            quantity={quantity}
            setQuantity={setQuantity}
            label="Anzahl"
            align="center"
          />
          <UpdateButton
            onClick={() => updateLineItem(checkout.id, item.id, quantity)}
            disabled={quantity === item.quantity}
          >
            Aktualisieren
          </UpdateButton>
        </QuantityWrapper>
        <SimpleButton
          onClick={() => removeLineItem(checkout.id, item.id)}
          style={{ gridArea: "remove" }}
        >
          Entfernen
        </SimpleButton>
      </CartItemDetails>
    </LineItemRow>
  )
}

const LineItemRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: right;
  /* align-items: center; */
  padding: 1rem;
  margin: 2rem 1rem;
  background-color: white;
  max-width: 600px;
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 5%) 0px 1px 10px;
  gap: 1rem;

  @media (min-width: 500px) {
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    margin: 2rem auto;
  }
`

const LeftColumn = styled.div`
  a {
    text-decoration: none;
    font-family: ${({ theme }) => theme.fontHeading};
    color: ${({ theme }) => theme.colors.primary};
  }
`

const LeftContainer = styled.div`
  h4 {
    margin: 0 0 1rem 0;
  }
`

const QuantityWrapper = styled.div`
  display: grid;
  grid-area: quantity;
  grid-template-columns: 1fr;
`

export const SimpleButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.colors.text};
  padding: 0.25rem 0.5rem;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text};
  transition: all 0.5s ease 0s;
  cursor: pointer;
  margin-top: 0.5rem;
  font-size: 12px;
  font-weight: 600;

  &:hover {
    box-shadow: ${({ theme }) => theme.colors.text} 0px 0px 0px 1px;
  }

  ${props =>
    props.disabled &&
    css`
      cursor: inherit;
      color: #b4b4b4;
      border: 1px solid #b4b4b4;
      opacity: 0;

      :hover {
        box-shadow: none;
      }
    `}

  svg {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
`

export const UpdateButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #ffd814;
  border-radius: 2px;
  border: 1px solid #fcd200;
  padding: 0.25rem 0.5rem;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text};
  transition: all 0.5s ease 0s;
  cursor: pointer;
  margin-top: 0.5rem;
  font-size: 12px;
  font-weight: 600;

  &:hover {
    box-shadow: #c5a400 0px 0px 0px 1px;
  }

  ${props =>
    props.disabled &&
    css`
      cursor: inherit;
      opacity: 0;

      :hover {
        box-shadow: none;
      }
    `}

  svg {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
`

const CartItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.05);

  @media (min-width: 500px) {
    align-items: flex-end;
    border-top: none;
    padding-right: 1rem;
  }
`

const ArticlePrice = styled.span`
  display: block;
  grid-area: price;
  font-weight: bold;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
`

export default CartLineItem
